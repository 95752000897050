<template>
  <shop-order-detail
    :buy_items="order_info.items"
    :order_info="order_info"
  ></shop-order-detail>
</template>

<script>
import { mapGetters } from "vuex";
import ShopOrderDetail from "./Widgets/ShopOrderDetail.vue";

export default {
  data() {
    return {
      scheduleInfo: null,
      order_info: { items: [] },
    };
  },
  components: {
    ShopOrderDetail,
  },
  mounted() {
    if (this.$route.query.order_id) {
      this.order_id = this.$route.query.order_id;
    }
    if (this.$route.query.back == 1) {
      console.log("allow back");
      this.allow_back = true;
    }
    console.log(`--> order id ${this.order_id}`);
    this.getOrderData();
  },
  methods: {
    ...mapGetters(["getToken"]),

    getOrderData() {
      if (this.order_id == null) {
        this.loading = false;
        console.log("no found,show not found");
        return;
      }
      const token = this.getToken();
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/shops/${this.order_id}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      const currentObj = this;
      currentObj.loading = true;
      currentObj.result_display = true;
      this.axios
        .get(url, config)
        .then((response) => {
          currentObj.setResult(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setResult(data) {
      console.log(data);
      this.buy_items = data.items;
      this.order_info = data;
    },
  },
};
</script>
